import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/captcha/defaultState';
const state = defaultState();
const getters = {};
const mutations = { ...mixinMutations() };
const actions = {
    resetCaptcha({ commit }) {
        commit('setProperty', { name: 'captchaKey', value: '' });
        if (!window.smartCaptcha)
            return;
        window.smartCaptcha.reset();
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
