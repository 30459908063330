import Vue from 'vue';
import Vuex from 'vuex';
import company from './modules/company/company';
import roles from './modules/roles';
import groups from './modules/groups/groups';
import customFieldsTable from './modules/customField/customFieldsTable';
import customFieldVariables from './modules/customField/customFieldVariables';
import form from './modules/form/form';
import formField from './modules/formField/formField';
import courses from './modules/courses/courses';
import students from './modules/students/students';
import customFieldPage from './modules/customField';
import courseAndPayments from './modules/courseAndPayments';
import customFieldDetails from './modules/customFieldDetails';
import modalRelations from './modules/modalRelations';
import question from './modules/question/question';
import testsTable from './modules/tests/tests';
import invites from './modules/invites/invites';
import adminProfile from './modules/adminProfile/adminProfile';
import courseEdit from './modules/courseEdit';
import courseList from './modules/courseList';
import coursePage from './modules/course';
import courseSearch from './modules/courseSearch';
import courseTypes from './modules/courseTypes';
import courseCompilation from './modules/courseCompilation';
import lessonPage from './modules/lesson';
import courseAndLessonMenu from './modules/courseAndLessonMenu/index';
import testPage from './modules/test';
import usersAccess from './modules/userAccess/usersAccess';
import categories from './modules/categories/index';
import locations from './modules/locations';
import Comments from './modules/Comments';
import Statuses from './modules/statuses';
import Orders from './modules/orders';
import OrderPage from './modules/orderPage';
import debug from './modules/debug/debug';
import testResults from './modules/testResults/testResults';
import users from './modules/users';
import news from './modules/news/index';
import autoActionList from './modules/autoActionList';
import autoActionDetails from './modules/autoActionDetails';
import autoActionTemplateList from './modules/autoActionTemplateList';
import autoActionTemplateDetails from './modules/autoActionTemplateDetails';
import Constructor from './modules/constructor';
import Layers from './modules/layers/layers';
import generateAccounts from './modules/generateAccounts/generateAccounts';
import Instructions from './modules/instructions';
import CompanyInstructions from './modules/instructions/company';
import InstructionCategories from './modules/instructions/categories';
import Pages from './modules/pages';
import breadcrumbs from './modules/breadcrumbs/index';
import integrations from './modules/integrations';
import library from './modules/library';
import courseLanding from './modules/courseLanding';
import publicMenu from './modules/publicMenu';
import faq from './modules/faq';
import advantages from './modules/advantages';
import ratings from './modules/ratings';
import reviews from './modules/reviews';
import courseNews from './modules/courseNews';
import catalogSettings from './modules/catalogSettings';
import userScripts from './modules/userScripts';
import coursePackages from './modules/coursePackages';
import courseBranches from './modules/courseBranches';
import user from './modules/user';
import Articles from './modules/articles';
import achievements from './modules/achievements';
import ArticlePage from './modules/articlePage';
import AdminHeader from './modules/adminHeaders/adminHeader';
import promoCodes from './modules/promoCodes';
import CourseGroups from './modules/courseGroups';
import CourseGroupDetails from './modules/courseGroupDetails';
import fileStorage from './modules/fileStorage';
import seoPages from './modules/seoPages';
import galleries from './modules/galleries';
import webinars from './modules/webinars';
import leadsFilter from './modules/leadsFilter';
import landingBanners from './modules/landingBanners';
import unitedTables from './modules/unitedTables';
import managerDashboard from './modules/managerDashboard';
import events from './modules/events';
import salesGenerator from './modules/salesGenerator';
import olympicTests from './modules/olympicTests';
import companyIntegrations from './modules/companyIntegrations';
import moderateReviews from './modules/moderateReviews';
import universities from './modules/universities';
import infoBlocks from './modules/infoBlocks';
import ServiceApi from '@/api/ServiceApi';
import { getData, postData } from '@/api';
import statistics from './modules/statistics';
import datesSelect from './modules/datesSelect';
import captcha from './modules/captcha/captcha';
Vue.use(Vuex);
const options = {
    getters: {
        COMPANY_ID(state, getters, rootState) {
            return rootState.company.id;
        },
        API() {
            return '/api';
        },
        API_URL(state, getters) {
            ServiceApi.initialize({ getData, postData, API_URL: `/api/companies/${getters.COMPANY_ID}` });
            return `/api/companies/${getters.COMPANY_ID}`;
        },
        API_PUBLIC_URL(state, getters) {
            return `/api/public/companies/${getters.COMPANY_ID}`;
        }
    },
    modules: {
        company,
        roles,
        groups,
        AdminHeader,
        autoActionList,
        autoActionDetails,
        autoActionTemplateList,
        autoActionTemplateDetails,
        customFieldsTable,
        customFieldVariables,
        form,
        formField,
        invites,
        courses,
        courseSearch,
        students,
        question,
        customFieldPage,
        customFieldDetails,
        modalRelations,
        adminProfile,
        courseEdit,
        courseList,
        courseTypes,
        coursePage,
        lessonPage,
        courseAndLessonMenu,
        testPage,
        usersAccess,
        categories,
        testsTable,
        Comments,
        Statuses,
        leadsFilter,
        Orders,
        OrderPage,
        debug,
        testResults,
        users,
        news,
        Constructor,
        Layers,
        Instructions,
        InstructionCategories,
        CompanyInstructions,
        Pages,
        generateAccounts,
        breadcrumbs,
        integrations,
        library,
        publicMenu,
        faq,
        advantages,
        ratings,
        reviews,
        moderateReviews,
        locations,
        achievements,
        courseNews,
        courseCompilation,
        catalogSettings,
        courseLanding,
        userScripts,
        coursePackages,
        courseBranches,
        user,
        Articles,
        ArticlePage,
        promoCodes,
        CourseGroups,
        CourseGroupDetails,
        fileStorage,
        seoPages,
        webinars,
        galleries,
        courseAndPayments,
        landingBanners,
        events,
        unitedTables,
        managerDashboard,
        companyIntegrations,
        olympicTests,
        universities,
        salesGenerator,
        infoBlocks,
        statistics,
        datesSelect,
        captcha
    }
};
export default new Vuex.Store(options);
